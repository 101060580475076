var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title",attrs:{"title":_vm.titleName}},[_vm._v(_vm._s(_vm.titleName))]),_c('div',{staticClass:"search"},[_c('el-input',{staticClass:"input-with-select",attrs:{"placeholder":"请输入内容"},model:{value:(_vm.SearchValue),callback:function ($$v) {_vm.SearchValue=$$v},expression:"SearchValue"}},[_c('el-select',{staticStyle:{"width":"100px"},attrs:{"slot":"prepend","placeholder":"请选择"},slot:"prepend",model:{value:(_vm.SearchType),callback:function ($$v) {_vm.SearchType=$$v},expression:"SearchType"}},[_c('el-option',{attrs:{"label":"文件名","value":"1"}}),_c('el-option',{attrs:{"label":"文字内容（模糊）","value":"3"}}),_c('el-option',{attrs:{"label":"文字内容（精确）","value":"4"}})],1),_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.onSearch},slot:"append"})],1)],1),_c('ul',_vm._l((_vm.thumbnailList),function(v){return _c('li',{key:v.id,staticClass:"item"},[_c('div',{staticClass:"box",class:{ active: v.id === _vm.activeId },on:{"click":function($event){return _vm.viewPic(v)}}},[_c('el-image',{attrs:{"fit":"contain","src":`/api/fileinfo/image/GetOriImageThumbnail?filename=${v.pictureFullName}`}}),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(v.pictureFileName))])],1)])}),0),_c('el-pagination',{attrs:{"hide-on-single-page":true,"small":"","layout":"prev, pager, next,total","current-page":_vm.pageIndex,"total":_vm.total},on:{"current-change":_vm.onCurrentChange}})],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"ebook"},[_c('div',{directives:[{name:"drag",rawName:"v-drag"}],ref:"img_box",staticClass:"imgBox",style:({
          transform: `translate(0px, 0px) scale(${_vm.zoom})`,
          transformOrigin: 'left top',
          margin: '0 auto',
        })},[_c('div',{ref:"dropBox",staticClass:"dropBox",staticStyle:{"z-index":"-1"},on:{"contextmenu":function($event){$event.preventDefault();$event.stopPropagation();return _vm.dropclick.apply(null, arguments)}}},[_c('div',{ref:"dropopen",staticClass:"dropwindow",attrs:{"id":"dropopen"}},[_c('div',{staticClass:"drop-list",on:{"click":_vm.cancelCon}},[_vm._v("取消选区")]),_c('div',{staticClass:"drop-list",on:{"click":function($event){return _vm.copyText('1')}}},[_vm._v("生成横排段落")]),_c('div',{staticClass:"drop-list",on:{"click":function($event){return _vm.copyText('0')}}},[_vm._v("生成竖排段落")])])]),_vm._l((_vm.positionList),function(v,i){return _c('div',{key:i,ref:"mask",refInFor:true,staticClass:"mask",style:({
            width: v.width * _vm.zoomScale + 'px',
            height: v.height * _vm.zoomScale + 'px',
            top: v.top * _vm.zoomScale + 'px',
            left: v.left * _vm.zoomScale + 'px',
            position: 'absolute',
            background: 'rgba(255, 255, 0, .5)',
            zIndex: 9,
          }),attrs:{"title":v.code}})}),(_vm.imgSrc)?_c('img',{staticStyle:{"height":"100%","object-fit":"contain"},attrs:{"src":`/api/fileinfo/image/GetOriImage?filename=${_vm.imgSrc}`,"draggable":"false"},on:{"load":_vm.onLoad,"wheel":_vm.changeZoom}}):_vm._e()],2),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isParagraphShow),expression:"!isParagraphShow"}],staticClass:"img",attrs:{"src":require("../../assets/展开.png"),"alt":""},on:{"click":_vm.imgClick}}),_c('div',{staticStyle:{"position":"absolute","bottom":"0"}},[_c('el-button',{on:{"click":function($event){return _vm.pageTurning(0)}}},[_vm._v("上一页")]),_c('el-button',{on:{"click":function($event){return _vm.pageTurning(1)}}},[_vm._v("下一页")])],1)]),_c('div',{ref:"paragraph",staticClass:"paragraph",style:({ width: _vm.isParagraphShow ? '30%' : '0%' })},[(_vm.isParagraphShow)?[_c('div',{staticClass:"text"},[_c('div',{staticClass:"operation"},[_c('div',[_vm._v("书写顺序：")]),_c('el-radio',{attrs:{"label":"1"},on:{"input":_vm.onRadioChange},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v("横排")]),_c('el-radio',{attrs:{"label":"2"},on:{"input":_vm.onRadioChange},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v("竖排")])],1),_c('div',{class:[_vm.radio === '1' ? 'test2' : 'test1']},[_c('span',{attrs:{"id":"articleDetailsRight"},domProps:{"innerHTML":_vm._s(_vm.paragraph)}})])]),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isParagraphShow),expression:"isParagraphShow"}],staticClass:"img",attrs:{"src":require("../../assets/展开.png"),"alt":""},on:{"click":_vm.imgClick}})]:_vm._e()],2)]),_c('range-content',{attrs:{"rangeDialogVisible":_vm.rangeDialogVisible,"content":_vm.rangeContent},on:{"update:rangeDialogVisible":function($event){_vm.rangeDialogVisible=$event},"update:range-dialog-visible":function($event){_vm.rangeDialogVisible=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }