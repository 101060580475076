<template>
  <div class="container">
    <div class="left">
      <div class="title" :title="titleName">{{ titleName }}</div>
      <div class="search">
        <el-input
          placeholder="请输入内容"
          v-model="SearchValue"
          class="input-with-select"
        >
          <el-select
            v-model="SearchType"
            slot="prepend"
            style="width: 100px"
            placeholder="请选择"
          >
            <el-option label="文件名" value="1"></el-option>
            <el-option label="文字内容（模糊）" value="3"></el-option>
            <el-option label="文字内容（精确）" value="4"></el-option>
          </el-select>
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="onSearch"
          ></el-button>
        </el-input>
      </div>
      <ul>
        <li class="item" v-for="v in thumbnailList" :key="v.id">
          <div
            class="box"
            :class="{ active: v.id === activeId }"
            @click="viewPic(v)"
          >
            <el-image
              fit="contain"
              :src="`/api/fileinfo/image/GetOriImageThumbnail?filename=${v.pictureFullName}`"
            ></el-image>
            <span style="font-size: 14px">{{ v.pictureFileName }}</span>
          </div>
        </li>
      </ul>
      <el-pagination
        :hide-on-single-page="true"
        small
        layout="prev, pager, next,total"
        :current-page="pageIndex"
        :total="total"
        @current-change="onCurrentChange"
      >
      </el-pagination>
    </div>
    <div class="right">
      <div class="ebook">
        <div
          class="imgBox"
          ref="img_box"
          :style="{
            transform: `translate(0px, 0px) scale(${zoom})`,
            transformOrigin: 'left top',
            margin: '0 auto',
          }"
          v-drag
        >
          <div
            style="z-index: -1"
            class="dropBox"
            ref="dropBox"
            @contextmenu.prevent.stop="dropclick"
          >
            <div class="dropwindow" ref="dropopen" id="dropopen">
              <div class="drop-list" @click="cancelCon">取消选区</div>
              <div class="drop-list" @click="copyText('1')">生成横排段落</div>
              <div class="drop-list" @click="copyText('0')">生成竖排段落</div>
              <!-- <div class="drop-list" @click="generateSources">生成考据来源</div> -->
            </div>
          </div>
          <div
            class="mask"
            ref="mask"
            v-for="(v, i) in positionList"
            :key="i"
            :title="v.code"
            :style="{
              width: v.width * zoomScale + 'px',
              height: v.height * zoomScale + 'px',
              top: v.top * zoomScale + 'px',
              left: v.left * zoomScale + 'px',
              position: 'absolute',
              background: 'rgba(255, 255, 0, .5)',
              zIndex: 9,
            }"
          ></div>
          <!-- <el-image
            v-if="imgSrc"
            style="height: 100%; width: 100%"
            :src="`/api/fileinfo/image/GetOriImage?filename=${imgSrc}`"
            fit="contain"
            v-show="imgSrc"
            @load="onLoad"
            @wheel="changeZoom"
          > -->
          <!-- </el-image> -->
          <img
            style="height: 100%; object-fit: contain"
            :src="`/api/fileinfo/image/GetOriImage?filename=${imgSrc}`"
            v-if="imgSrc"
            draggable="false"
            @load="onLoad"
            @wheel="changeZoom"
          />
        </div>
        <img
          class="img"
          v-show="!isParagraphShow"
          src="../../assets/展开.png"
          alt=""
          @click="imgClick"
        />
        <div style="position: absolute; bottom: 0">
          <el-button @click="pageTurning(0)">上一页</el-button>
          <el-button @click="pageTurning(1)">下一页</el-button>
        </div>
      </div>
      <div
        class="paragraph"
        ref="paragraph"
        :style="{ width: isParagraphShow ? '30%' : '0%' }"
      >
        <template v-if="isParagraphShow">
          <div class="text">
            <div class="operation">
              <div>书写顺序：</div>
              <el-radio @input="onRadioChange" v-model="radio" label="1"
                >横排</el-radio
              >
              <el-radio @input="onRadioChange" v-model="radio" label="2"
                >竖排</el-radio
              >
            </div>
            <div :class="[radio === '1' ? 'test2' : 'test1']">
              <span id="articleDetailsRight" v-html="paragraph"> </span>
            </div>
          </div>
          <img
            class="img"
            src="../../assets/展开.png"
            v-show="isParagraphShow"
            alt=""
            @click="imgClick"
          />
        </template>
      </div>
    </div>
    <range-content
      :rangeDialogVisible.sync="rangeDialogVisible"
      :content="rangeContent"
    />
  </div>
</template>

<script>
import {
  getThumbnail,
  getInfo,
  getName,
  getRangeContent,
} from "@/api/literature.js";
import { BeforeOrNext } from "@/api/susername.js";
import rangeContent from "../ebook2/components/rangeContent.vue";
export default {
  components: { rangeContent },
  data() {
    return {
      scaleX: null,
      scaleY: null,
      rangeDialogVisible: false,
      rangeContent: {},
      positionObj: {},
      isDisable: false,
      titleName: "",
      thumbnailList: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      sourceID: "",
      SearchValue: "",
      activeId: "",
      imgSrc: "",
      radio: "2",
      paragraph: "",
      isParagraphShow: false,
      SearchType: "3",
      zoom: 1,
      nWidth: 0,
      nHeight: 0,
      zoomScale: 0,
      positionList: [],
    };
  },
  created() {
    this.SearchValue = this.$route.query.searchTitle || "";
    this.sourceID = this.$route.query?.sourceID;
    this.getName(this.sourceID);
    this.getThumbnail();
  },
  mounted() {
    this.pictureFrame();
  },
  watch: {
    isLoadingCompleted(val) {
      if (val && this.$route.query.id) {
        this.$refs.tree.setCurrentKey(this.$route.query.id);
        this.handleNodeClick(
          this.$refs.tree.getNode(this.$route.query.id).data
        );
      }
    },
  },
  methods: {
    // 考据来源
    generateSources() {
      const position = {
        x: parseInt(this.positionObj.x / this.scaleX),
        y: parseInt(this.positionObj.y / this.scaleY),
        width: parseInt(this.positionObj.width / this.scaleX) - 20,
        height: parseInt(this.positionObj.height / this.scaleY) - 20,
        sourceId: this.$route.query.sourceID,
        id: this.activeId,
      };

      this.$copyText(JSON.stringify(position)).then(
        (res) => {
          console.log(res);
          this.$message.success("生成复制成功");
          this.cancelCon();
        },
        (e) => {
          this.$message.error("生成复制失败");
          console.log(e);
          this.cancelCon();
        }
      );
    },
    // 翻页
    async pageTurning(type) {
      // if (
      //   this.activeId ===
      //     this.thumbnailList[this.thumbnailList.length - 1].id &&
      //   type === 1 &&
      //   this.pageIndex < this.total
      // ) {
      //   this.pageIndex += 1;
      //   this.getThumbnail();
      //   return;
      // } else if (
      //   this.activeId === this.thumbnailList[0].id &&
      //   type === 0 &&
      //   this.pageIndex > 1
      // ) {
      //   this.pageIndex -= 1;
      //   this.getThumbnail();
      //   return;
      // }
      try {
        const res = await BeforeOrNext({
          id: this.activeId,
          opType: type,
        });
        this.imgSrc = res.pictureFullName;
        this.activeId = res.genealogyOCRInfoID;
        this.cancelCon();
        this.getInfo(this.activeId);
      } catch (error) {
        console.log(error);
      }
    },
    // 右击画框
    dropclick(e) {
      let rightbox = this.$refs.dropopen;
      rightbox.style.right = "";
      rightbox.style.left = "";

      rightbox.style.top = e.offsetY + "px";
      if (this.positionObj.x + e.offsetX + 150 >= this.pageWidth / 2) {
        rightbox.style.right = this.positionObj.width - e.offsetX + "px";
      } else {
        rightbox.style.left = e.offsetX + "px";
      }
      rightbox.style.display = "flex";
    },
    async copyText(layout) {
      const loading = this.$loading({
        lock: true,
        target: ".body-content",
        text: "加载中...",
        spinner: "el-icon-loading",
      });
      try {
        const res = await getRangeContent({
          ocrInfoId: this.activeId,
          region: {
            location: {
              x: parseInt(this.positionObj.x / this.scaleX),
              y: parseInt(this.positionObj.y / this.scaleY),
            },
            size: {
              width: parseInt(this.positionObj.width / this.scaleX) - 20,
              height: parseInt(this.positionObj.height / this.scaleY) - 20,
            },
            x: parseInt(this.positionObj.x / this.scaleX),
            y: parseInt(this.positionObj.y / this.scaleY),
            width: parseInt(this.positionObj.width / this.scaleX) - 20,
            height: parseInt(this.positionObj.height / this.scaleY) - 20,
          },
          layout,
        });
        this.rangeContent = res;
        this.$refs.dropopen.style.display = "none";
        if (!this.rangeContent.content)
          return this.$message.info("暂无可生成的内容");
        this.rangeDialogVisible = true;
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    // 取消选区
    cancelCon() {
      let dropbox = this.$refs.dropBox;
      dropbox.style.left = 0;
      dropbox.style.top = 0;
      dropbox.style.width = 0;
      dropbox.style.height = 0;
      dropbox.style.zIndex = `-1`;
      let dropopen = this.$refs.dropopen;
      dropopen.style.display = `none`;
    },
    pictureFrame() {
      let that = this;
      let ele = that.$refs.img_box;
      ele.addEventListener("mousedown", (e) => {
        // if (e.button !== 0) return;
        // this.$refs.dropopen.style.display = "none";
        // 移动
        if (e.ctrlKey) {
          this.isDisable = true;
          if (this.isDisable) {
            e?.stopPropagation();
            that.cancelCon();
            that.isDrawing = false;
            that.scaleX = e.target.width / this.nWidth;
            that.scaleY = e.target.height / this.nHeight;

            //获取鼠标开始拖动的起始位置
            let a = {};
            a.x = e.offsetX;
            a.y = e.offsetY;
            // 可视区坐标
            let startPos = {};
            startPos.x = e.clientX;
            startPos.y = e.clientY;

            //鼠标按下移动时动态获取鼠标位置
            ele.addEventListener("mousemove", move);

            function move(e) {
              let element = that.$refs.dropBox;

              that.positionObj = {
                x: a.x,
                y: a.y,
                width: e.offsetX - a.x,
                height: e.offsetY - a.y,
              };
              element.style.width = e.offsetX - a.x - 5 + "px";
              element.style.height = e.offsetY - a.y - 5 + "px";
              element.style.left = a.x + "px";
              element.style.top = a.y + "px";
              element.style.border = `1px dashed red`;
              element.style.position = `absolute`;
              element.style.zIndex = `999999`;
            }

            //鼠标放下时，停止生成画框
            ele.addEventListener(
              "mouseup",
              function () {
                that.isDisable = false;
                ele.removeEventListener("mousemove", move);
              },
              {
                once: true,
              }
            );
          }
        }
      });
    },
    changeZoom(e) {
      // 放大
      if (e.deltaY < 0) {
        this.zoom += 0.1;
      } else {
        this.zoom -= 0.1;
      }
    },
    onLoad(e) {
      const path = e.path || (e.composedPath && e.composedPath());
      this.nWidth = path[0].naturalWidth;
      this.nHeight = path[0].naturalHeight;
      this.zoomScale = this.$refs.img_box.offsetHeight / this.nHeight;
      this.isLoadingCompleted = true;
    },
    onCurrentChange(index) {
      this.pageIndex = index;
      this.getThumbnail();
    },
    async getName(sourceID) {
      const res = await getName({
        sourceID,
      });
      this.titleName = res.title;
    },
    async getThumbnail() {
      const res = await getThumbnail({
        SourceID: this.sourceID,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
        SearchType: this.SearchType,
        SearchValue: this.SearchValue,
      });
      this.thumbnailList = res.items;
      this.total = res.totalCount;
      if (this.total == 0) return this.$message.info("暂无数据");
      if (this.total) {
        this.activeId = res.items[0].id;
        this.imgSrc = res.items[0].pictureFullName;
        this.getInfo(res.items[0].id);
      }
    },
    onSearch() {
      this.pageIndex = 1;
      this.getThumbnail();
    },
    viewPic(data) {
      this.imgSrc = data.pictureFullName;
      this.activeId = data.id;
      this.cancelCon();
      this.getInfo(data.id);
    },
    async getInfo(id) {
      const res = await getInfo({ id, keyWord: this.SearchValue });
      this.paragraph = res.content;
      this.positionList = res.chars.map((v) => {
        return {
          code: v.code,
          width: Math.abs(
            v.rcInImage.split(",")[0] - v.rcInImage.split(",")[2]
          ),
          height: Math.abs(
            v.rcInImage.split(",")[1] - v.rcInImage.split(",")[3]
          ),
          left: Math.abs(v.rcInImage.split(",")[0]),
          top: Math.abs(v.rcInImage.split(",")[1]),
        };
      });
    },
    // 改变书写顺序
    onRadioChange(e) {
      if (this.paragraph) {
        this.paragraph = this.paragraph
          .replace(/︵/g, "(")
          .replace(/︶/g, ")")
          .replace(/︽/g, "《")
          .replace(/︾/g, "》")
          .replace(/﹃/g, "『")
          .replace(/﹄/g, "』")
          .replace(/﹁/g, "「")
          .replace(/﹂/g, " 」");
        // .replace(/︹/g, " ")
        // .replace(/︺/g, " ");
      }
      this.radio = e;
    },
    imgClick() {
      this.isParagraphShow = !this.isParagraphShow;
      if (this.isParagraphShow) {
        this.$refs.paragraph.style.borderLeft = "1px solid #eee";
        this.$refs.paragraph.style.paddingLeft = "20px";
      } else {
        this.$refs.paragraph.style.borderLeft = "none";
        this.$refs.paragraph.style.paddingLeft = "0";
      }
    },
  },
  directives: {
    drag: {
      // 指令的定义
      inserted: (el, bingding, vnode) => {
        //获取元素
        // var dv = document.getElementById("dv");
        let x = 0;
        let y = 0;
        let l = 0;
        let t = 0;
        let isDown = false;
        let img = el.querySelector("img");
        //鼠标按下事件
        el.onmousedown = function (e) {
          //获取x坐标和y坐标
          x = e.clientX;
          y = e.clientY;

          //获取左部和顶部的偏移量
          l = el.offsetLeft;
          t = el.offsetTop;
          //开关打开
          isDown = true;
          //设置样式
          const originalScrollBehavior = el.style["scroll-behavior"];

          const originalPointerEvents = el.style["pointer-events"];
          el.style["scroll-behavior"] = "auto";

          //鼠标移动
          document.onmousemove = (e) => {
            // || vnode.context.isDisable
            if (isDown == false || vnode.context.isDisable) {
              return;
            }
            el.style["pointer-events"] = "none";
            //获取x和y
            let nx = e.clientX;
            let ny = e.clientY;
            //计算移动后的左偏移量和顶部的偏移量
            let nl = nx - (x - l);
            let nt = ny - (y - t);

            el.style.left = nl + "px";
            el.style.top = nt + "px";
          };
          //鼠标抬起事件
          document.onmouseup = () => {
            //开关关闭
            isDown = false;
            document.onmousemove = null;
            document.onmouseup = null;
            el.style["scroll-behavior"] = originalScrollBehavior;
            el.style["pointer-events"] = originalPointerEvents;
          };
        };
      },
    },
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.pictureFrame);
  },
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f2f2f2;
}

::-webkit-scrollbar-thumb {
  background: #9cc3ce;
  border-radius: 10px;
}

/* 其他浏览器 */
* {
  scrollbar-width: auto;
  scrollbar-color: #9cc3ce #f2f2f2;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  background: #9cc3ce;
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  .left {
    width: 395px;
    height: 100%;
    border-right: 1px solid rgb(79, 129, 189);
    overflow: auto;
    .title {
      font-size: 18px;
      color: #fff;
      background: rgb(79, 129, 189);
      padding: 15px;
      text-align: center;
      font-weight: 700;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .item {
      .box {
        cursor: pointer;
        width: 250px;
        height: 250px;
        border: 1px dashed #eee;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px auto;
        padding: 5px;

        span {
          margin-top: 5px;
        }
      }
      .active {
        border: 1px dashed red;
      }
      overflow: auto;
    }
  }
  .right {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px;
    overflow: hidden;

    .ebook {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .img {
        position: absolute;
        right: 0;
        top: 50%;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
        transform: rotate(180deg);
        cursor: pointer;
      }
      .search {
        width: 300px;
        height: 50px;
        line-height: 50px;
        margin: 0 auto;
      }
      .imgBox {
        position: absolute;
        height: calc(100% - 50px);

        .dropwindow {
          border-radius: 10px;
          width: 150px;
          height: 90px;
          background: rgb(255, 255, 255);
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          display: none;
          box-shadow: 2px 2px 5px #666;
          border: 1px solid #aab4b4;
          transform: scale(1);
          padding: 15px 0;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          -o-user-select: none;
          user-select: none;
        }

        .drop-list {
          line-height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          flex: 1;
          color: black;
          cursor: pointer;
          transform: scale(1);
          font-size: 16px;
        }
        .drop-list:hover {
          background: #ccc;
        }
      }
    }
    .paragraph {
      height: 100%;
      overflow: auto;
      // border-left: 1px solid #eee;
      // padding-left: 20px;
      position: relative;
      transition: all 0.3s ease;
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      .img {
        position: absolute;
        left: 0;
        top: 50%;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
        cursor: pointer;
      }

      .text {
        position: relative;
        padding-top: 52px;
        height: calc(100% - 60px);
        background: #fff;
        display: flex;
        justify-content: flex-end;

        ::v-deep .operation {
          padding-right: 26px;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          height: 50px;
          font-size: 18px;
          color: #000;
          display: flex;
          border-bottom: 1px solid #ccc;
          align-items: center;
          justify-content: flex-end;

          .el-radio__label {
            font-size: 18px;
            color: #000;
          }

          .el-radio__inner {
            width: 18px;
            height: 18px;
          }
        }
        .test1 {
          overflow-y: auto;
          font-size: 20px;
          color: #000;
          writing-mode: vertical-lr;
          writing-mode: tb-rl;
          font-family: 宋体;
          white-space: pre-wrap;
          line-height: 22px;
          letter-spacing: 2px;
        }
        .test1:focus {
          transition: border linear 0.1s, box-shadow linear 0.2s;
          box-shadow: 0 0 10px #66afe9; //向外漫射程度1
          -moz-box-shadow: 0 0 10px #66afe9; //向外漫射程度2
          -webkit-box-shadow: 0 0 10px #66afe9; //向外漫射程度3
          border: 1px solid #66afe9;
          outline: 0;
          padding: 9px;
        }

        .test2 {
          overflow-y: auto;
          font-size: 20px;
          color: #000;
          // writing-mode: vertical-lr;
          // writing-mode: tb-rl;
          font-family: 宋体;
          white-space: pre-wrap;
          line-height: 22px;
          letter-spacing: 2px;
        }
      }
    }
  }
}
</style>